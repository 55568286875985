import {processUniversalLinkMode} from '@/utils/app.util'

(() => {
  // Check if in universal links mode
  const universalLinkRoutes = [
    '/app',
    '/final_tax_return'
  ]
  const universalLinkMode = universalLinkRoutes.some((route) => {
    return window.location.pathname.startsWith(route)
  })
  if (universalLinkMode) {
    processUniversalLinkMode()
    return
  }

  import('@/bootstrap')
})()
